import { useState } from 'react';

import MetaTags from '@otovo/shared/components/Sanity/MetaTags';
import getTitleFromSanityCampaignPage from '@otovo/shared/components/Sanity/getTitleFromSanityPage';
import { ADDRESS_SEARCH_FIELD_PLACEHOLDER } from '@otovo/shared/constants/otovoweb';
import { Sanity$CampaignPage } from '@otovo/shared/types/sanityTypes';

import dynamic from 'next/dynamic';
import ContentRowList from './ContentRowList';
import HeroSection from './components/HeroSection/HeroSection';

const LandingPageNotification = dynamic(
  () => import('../LandingPageNotification/LandingPageNotification'),
);
const SanityBanner = dynamic(
  () => import('../LandingPageNotification/SanityBanner'),
);

type Props = {
  page: Sanity$CampaignPage;
};

const SanityLandingPage = ({ page }: Props) => {
  const [showBanner, setShowBanner] = useState(!!page.banner?.content);

  const heroAddressBar = [];
  const contentRows = page.rows.filter((row, i) => {
    if (
      i === 0 &&
      'placeholder' in row &&
      row.placeholder === ADDRESS_SEARCH_FIELD_PLACEHOLDER
    ) {
      heroAddressBar.push(row);
      return false;
    }
    return true;
  });

  const { faqItems = null, mainHardwareType } = page;
  const rowContext = {
    faqItems,
    mainHardwareType,
  };

  return (
    <>
      <LandingPageNotification>
        {page.banner?.content && (
          <SanityBanner
            banner={page.banner}
            onDismiss={() => setShowBanner(false)}
          />
        )}
      </LandingPageNotification>
      <MetaTags
        meta={page.meta}
        defaultTags={{
          title: getTitleFromSanityCampaignPage(page),
          description: page.lead || '',
          image: page.mainImage,
        }}
      />
      <HeroSection
        page={page}
        addressBarRow={heroAddressBar}
        isBannerShown={showBanner}
      />
      <div className="bg-lilac-1">
        <ContentRowList rows={contentRows} rowContext={rowContext} />
      </div>
    </>
  );
};

export default SanityLandingPage;
